import { useEffect, useState } from 'react';

import { SatisfactionLevel } from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  Slider, SpinnerIcon, SpinnerWithLogo, useLabels,
} from 'ui';
import {
  Button,
  Dialog, DialogContent, IconButton, Stack, Typography,
  useTheme,
} from '@mui/material';

import { useUpdateOwnerRetentionDetails } from '../../../api/owner-risk';
import { ownerSentimentDialogState } from '../state';

const satisfactionLevelDetails = {
  [SatisfactionLevel.VERY_DISSATISFIED]: { label: 'Very Dissatisfied', emoji: '😡' },
  [SatisfactionLevel.DISSATISFIED]: { label: 'Dissatisfied', emoji: '😞' },
  [SatisfactionLevel.NEUTRAL]: { label: 'Neutral', emoji: '😐' },
  [SatisfactionLevel.SATISFIED]: { label: 'Satisfied', emoji: '☺️' },
  [SatisfactionLevel.VERY_SATISFIED]: { label: 'Very Satisfied', emoji: '😍' },
};

const satisfactionLevels = [
  SatisfactionLevel.VERY_DISSATISFIED,
  SatisfactionLevel.DISSATISFIED,
  SatisfactionLevel.NEUTRAL,
  SatisfactionLevel.SATISFIED,
  SatisfactionLevel.VERY_SATISFIED,
];

export const OwnerSentimentDialog = () => {
  const l = useLabels();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedLevelNumber, setSelectedLevelNumber] = useState<number>(
    satisfactionLevels.indexOf(SatisfactionLevel.NEUTRAL),
  );
  const selectedLevel = satisfactionLevels[selectedLevelNumber];
  const {
    mutateAsync: updateOwnerRetentionDetails,
    isLoading: isUpdatingOwnerRetentionDetails,
  } = useUpdateOwnerRetentionDetails();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    ownerSentimentDialogState.value.openDialog = () => setOpen(true);
  }, []);

  useEffect(() => {
    if (ownerSentimentDialogState?.value.satisfactionLevel) {
      setSelectedLevelNumber(
        satisfactionLevels.indexOf(ownerSentimentDialogState?.value.satisfactionLevel),
      );
    }
  }, [ownerSentimentDialogState?.value.satisfactionLevel]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSelectedLevelNumber(newValue as number);
  };

  const handleSubmit = async () => {
    try {
      await updateOwnerRetentionDetails({
        ownerEmail: ownerSentimentDialogState.value.ownerEmail!,
        ownerRiskID: ownerSentimentDialogState.value.ownerRiskID!,
        satisfactionLevel: selectedLevel,
      });
      await ownerSentimentDialogState.value.afterSubmit();
      handleClose();
    } catch (error) {
      console.error(error);

      toast.error(l['error.unknownError']);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={5}
        py={2}
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        <Typography variant="h6">
          {l['pm-dashboard.ownerSentimentDialog.title']}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          edge="end"
        >
          <MdClose />
        </IconButton>
      </Stack>
      <DialogContent>
        {!ownerSentimentDialogState.value.ownerRiskID ? (
          <Stack p={10}>
            <SpinnerWithLogo />
          </Stack>
        ) : (
          <>
            <Stack gap={5}>
              <Typography variant="body1" sx={{ textAlign: 'center', px: 2 }}>
                {l['pm-dashboard.ownerSentimentDialog.description']}
              </Typography>
              <Stack gap={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} px={4} height={100}>
                  {satisfactionLevels.map((level) => (
                    <Typography
                      key={satisfactionLevelDetails[level].label}
                      onClick={() => setSelectedLevelNumber(satisfactionLevels.indexOf(level))}
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                        transition: 'font-size 0.3s ease, opacity 0.3s ease',
                        fontSize: selectedLevel === level ? '3.5rem' : '2.25rem',
                        opacity: selectedLevel === level ? 1 : 0.5,
                      }}
                    >
                      {satisfactionLevelDetails[level].emoji}
                    </Typography>
                  ))}
                </Stack>
                <Stack alignItems="center">
                  <Typography variant="caption">{satisfactionLevelDetails[selectedLevel].label}</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              mt={5}
              px={2}
              py={0.5}
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 200,
              }}
            >
              <Slider
                value={selectedLevelNumber}
                onChange={handleSliderChange}
                step={1}
                min={0}
                max={Object.keys(SatisfactionLevel).length - 1}
              />
            </Stack>
          </>
        )}
      </DialogContent>
      <Stack alignItems="end" px={5} py={3} borderTop={`1px solid ${theme.palette.divider}`}>
        <Button
          variant="outlined"
          onClick={handleSubmit}
          size="large"
          disabled={!ownerSentimentDialogState.value.ownerRiskID || isUpdatingOwnerRetentionDetails}
        >
          {isUpdatingOwnerRetentionDetails ? <SpinnerIcon size={20} /> : l['pm-dashboard.ownerSentimentDialog.submit']}
        </Button>
      </Stack>
    </Dialog>
  );
};
