import { useState } from 'react';

import { FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { BoldTypography, InvertedButton, useLabels } from 'ui';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, IconButton,
  InputLabel, MenuItem, Select, Stack,
  Typography, useMediaQuery, useTheme,
} from '@mui/material';

export type InsightFilter = {
  insight: string;
  id: number;
  condition: string;
  insightType: string;
  levels: string[];
};

export const FilterDialog = ({
  open,
  onClose,
  insightsCaseName,
  onApply,
}: {
  open: boolean,
  onClose: () => void,
  onApply: (filters: InsightFilter[]) => void,
  insightsCaseName: string[]
}) => {
  const l = useLabels();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const defaultFilter = {
    insight: '',
    id: 0,
    condition: 'is',
    insightType: 'NEGATIVE',
    levels: ['LOW'],
  };

  const [selectedInsightFilter, setSelectedInsightFilter] = useState<InsightFilter[]>([defaultFilter]);
  const [selectedInsightIsValid, setSelectedInsightIsValid] = useState<boolean>(true);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          maxWidth: isMobile ? '100%' : '700px',
          width: '100%',
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l.filters}
          </BoldTypography>
          <IconButton onClick={() => {
            setSelectedInsightFilter([defaultFilter]);
            onApply([]);
          }}
          >
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <Stack gap={4} height="100%" pt={5}>
          <Typography pl={1} variant="body1">By insights</Typography>
          {selectedInsightFilter.map((si, index) => (
            <Stack>
              <Stack key={si.id.toString()} direction="row" spacing={2} alignItems="center">
                <Stack width="180">
                  <FormControl fullWidth>
                    <InputLabel id="team-member-role-label">{l.selectInsight}</InputLabel>
                    <Select
                      sx={{ height: '48px', width: '180px' }}
                      labelId="team-member-role-label"
                      value={si.insight}
                      label={l.selectInsight}
                      onChange={(e) => {
                        selectedInsightFilter[index].insight = e.target.value;
                        const newSelectedFilter = [...selectedInsightFilter];
                        setSelectedInsightFilter(newSelectedFilter);
                      }}
                    >
                      {insightsCaseName.map((ins) => (
                        <MenuItem key={ins} value={ins}>
                          <Stack direction="column" spacing={2}>
                            <Typography variant="body1">{l.dynamic['retention.insight'](ins)}</Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack width="90px">
                  <FormControl fullWidth>
                    <Select
                      sx={{ height: '48px', width: '90px' }}
                      value={si.condition}
                      onChange={(e) => {
                        selectedInsightFilter[index].condition = e.target.value;
                        setSelectedInsightFilter([...selectedInsightFilter]);
                      }}
                    >
                      <MenuItem value="is">
                        <Stack direction="column" spacing={2}>
                          <Typography variant="body1">{l.is}</Typography>
                        </Stack>
                      </MenuItem>
                      <MenuItem value="is_not">
                        <Stack direction="column" spacing={2}>
                          <Typography variant="body1">{l.isNot}</Typography>
                        </Stack>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack width="120px">
                  <FormControl fullWidth>
                    <Select
                      sx={{ height: '48px', width: '120px' }}
                      value={si.insightType}
                      onChange={(e) => {
                        selectedInsightFilter[index].insightType = e.target.value;
                        setSelectedInsightFilter([...selectedInsightFilter]);
                      }}
                    >
                      <MenuItem value="POSITIVE">
                        <Stack direction="column" spacing={2}>
                          <Typography variant="body1">
                            {l.positive}
                          </Typography>
                        </Stack>
                      </MenuItem>
                      <MenuItem value="NEGATIVE">
                        <Stack direction="column" spacing={2}>
                          <Typography variant="body1">
                            {l.negative}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack width="220px">
                  <FormControl fullWidth>
                    <Select
                      multiple
                      renderValue={(selected) => selected.join(', ')} // Show values inline
                      sx={{ height: '48px', width: '220px' }}
                      value={si.levels}
                      onChange={(e) => {
                        selectedInsightFilter[index].levels = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
                        setSelectedInsightFilter([...selectedInsightFilter]);
                      }}
                    >
                      <MenuItem value="LOW">
                        <Stack direction="column" spacing={2}>
                          <Typography variant="body1">{l.low}</Typography>
                        </Stack>
                      </MenuItem>
                      <MenuItem value="MEDIUM">
                        <Stack direction="column" spacing={2}>
                          <Typography variant="body1">{l.medium}</Typography>
                        </Stack>
                      </MenuItem>
                      <MenuItem value="HIGH">
                        <Stack direction="column" spacing={2}>
                          <Typography variant="body1">{l.high}</Typography>
                        </Stack>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                {selectedInsightFilter.length > 1 && (
                  <IconButton onClick={() => {
                    const newSelectedInsightFilter = selectedInsightFilter
                      .filter((_, ind) => index !== ind);

                    const insightFilter = newSelectedInsightFilter.map((sis, ind) => {
                      const updateSis = { ...sis };
                      updateSis.id = ind;
                      return updateSis;
                    });

                    setSelectedInsightFilter(insightFilter);
                  }}
                  >
                    <MdClose />
                  </IconButton>
                )}
              </Stack>
              {(!selectedInsightIsValid && si.insight === '') && (
                <Typography variant="body1" pt={2} pl={1} color="red" pr={4}>{l.pleaseSelectInsight}</Typography>
              )}
            </Stack>
          ))}
          <IconButton
            sx={{ alignSelf: 'flex-start' }}
            onClick={() => {
              selectedInsightFilter.push(
                {
                  insight: '',
                  condition: 'is',
                  insightType: 'POSITIVE',
                  id: selectedInsightFilter.length,
                  levels: ['LOW'],
                },
              );
              setSelectedInsightIsValid(true);
              setSelectedInsightFilter([...selectedInsightFilter]);
            }}
          >
            <FaPlus fill="#272629" size={12} />
            <Typography pl={2} variant="body1">{l.addFilter}</Typography>
          </IconButton>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" pt={3}>
          <Button
            onClick={() => {
              setSelectedInsightFilter([defaultFilter]);
            }}
            variant="text"
          >
            {l.clearAll}
          </Button>
          <Stack direction="row">
            <InvertedButton onClick={() => {
              if (selectedInsightFilter.filter((ins) => ins.insight === '').length === 1 && selectedInsightFilter.length === 1) {
                setSelectedInsightIsValid(true);
                onApply([]);
                return;
              }

              if (selectedInsightFilter.filter((ins) => ins.insight === '').length > 0) {
                setSelectedInsightIsValid(false);
                return;
              }
              setSelectedInsightIsValid(true);
              onApply(selectedInsightFilter);
            }}
            >
              {l.apply}
            </InvertedButton>
          </Stack>

        </Stack>

      </DialogActions>
    </Dialog>
  );
};
