import { useEffect, useRef, useState } from 'react';

import Cookies from 'js-cookie';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import { IconButton } from '@mui/material';

export const BackgroundMusicPlayer = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(Cookies.get('muteStatus') === 'false');

  useEffect(() => {
    const savedMuteStatus = Cookies.get('muteStatus');
    if (savedMuteStatus === 'true') {
      setIsPlaying(false);
      audioRef.current?.pause();
    }
    if (audioRef.current) {
      audioRef.current.volume = 0.2; // Set volume to 20%
    }
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      Cookies.set('muteStatus', 'true');
    } else {
      audioRef.current?.play();
      Cookies.set('muteStatus', 'false');
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        src="https://assets.blankethomes.com/gi/bgmusic.mp3"
        loop
      />
      <IconButton onClick={togglePlayPause}>
        {isPlaying ? <MdVolumeUp color="black" /> : <MdVolumeOff color="black" />}
      </IconButton>
    </div>
  );
};
