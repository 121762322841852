import { useEffect } from 'react';

import {
  useAnalytics, useAuth, useGetPMSettings,
  useGetUserActions, useListOwnerProperties, useTrackPageBottom,
} from 'lib';
import { AnnualReport, annualReportLeadershipSummaryState, FallbackSpinner } from 'ui';

export const AnnualReportIndex = () => {
  const { user } = useAuth();
  const { data: pmSettings, isLoading } = useGetPMSettings();

  const analytics = useAnalytics();
  useTrackPageBottom(() => {
    analytics.track('Page Bottom Reached', {
      pageName: 'Annual Report',
      user: user?.id,
    });
  });

  const { isLoading: isLoadingOwnerProperties } = useListOwnerProperties(user?.id ?? '');
  const { data: userActions, isLoading: isLoadingUserActions } = useGetUserActions(user?.id ?? '');

  useEffect(() => {
    annualReportLeadershipSummaryState.value = pmSettings?.leadershipSummary2024 ?? '';

    return () => {
      annualReportLeadershipSummaryState.value = '';
    };
  }, [pmSettings?.leadershipSummary2024]);

  if (isLoading || isLoadingOwnerProperties || isLoadingUserActions) {
    return <FallbackSpinner />;
  }

  return (
    <AnnualReport
      ownerFullName={user ? `${user.givenName} ${user.familyName}` : ''}
      showHeader
      userActions={userActions}
      owner={user?.id ?? ''}
    />
  );
};
