import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  useAnalytics,
  useAuth,
  useFeatures,
  useGetUserActions, useListingCardGrossYieldAsDollar, useMutateUserActions,
} from 'lib';
import {
  BoldTypography,
  PageTitle,
  Spacer, Store, useLabels,
} from 'ui';
import { z } from 'zod';
import {
  Box,
  Grid, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { FiltersContainer } from './filters/Filters';
import { InviteProspectBanner } from './filters/InviteProspectBanner';
import { BuyBox } from './BuyBox';
import { CategoryListings, CategoryListingsProps } from './CategoryListings';
import { ExpandableListingProps, ExpandableListings } from './ExpandableListings';
import { InviteOwnersBanner } from './InviteOwnersBanner';
import { SettableFilters } from './props';
import { getListingsFromPages } from './utils';
import { WelcomeToMarketplaceDialog } from './WelcomeToMarketplace';
import { useSearchProperties } from '../../api/properties';
import { Category, Filters, filterSchema } from '../../api/properties/searchable';
import { WantToSellPropertyWidget } from '../../components/sell-property/SellPropertyButton';
import { useGetB2CReferralProgram } from '../../hooks/useGetB2CReferralProgram';
import { ListingProperty } from '../../types/property';

import './carousel.css';
import 'react-multi-carousel/lib/styles.css';

const toggleCategory = (categories: Category[], category: Category) => {
  if (categories.includes(category)) {
    return categories.filter((c) => c !== category);
  }

  return [...categories, category];
};

const useFetchAllCategories = (filters: Filters, propertiesPerPage: number, filtersCleared: boolean) => {
  const {
    data: mixedCategoryData,
    isLoading: mixedCategoryIsLoading,
    fetchNextPage: mixedCategoryFetchNextPage,
    hasNextPage: mixedCategoryHasNextPage,
    isFetchingNextPage: mixedCategoryIsFetchingNextPage,
  } = useSearchProperties({
    ...filters,
    categories: filters.categories,
  }, propertiesPerPage, !filtersCleared);
  const {
    data: internalData,
    isLoading: internalIsLoading,
    fetchNextPage: internalFetchNextPage,
    hasNextPage: internalHasNextPage,
    isFetchingNextPage: internalIsFetchingNextPage,
  } = useSearchProperties({
    ...filters,
    categories: ['internal'],
  }, propertiesPerPage);
  const {
    data: newConstructionData,
    isLoading: newConstructionIsLoading,
    fetchNextPage: newConstructionFetchNextPage,
    isFetchingNextPage: newConstructionIsFetchingNextPage,
    hasNextPage: newConstructionHasNextPage,
  } = useSearchProperties({
    ...filters,
    categories: ['new_construction'],
  }, propertiesPerPage);
  const {
    data: wholesaleData,
    isLoading: wholesaleIsLoading,
    fetchNextPage: wholesaleFetchNextPage,
    isFetchingNextPage: wholesaleIsFetchingNextPage,
    hasNextPage: wholesaleHasNextPage,
  } = useSearchProperties({
    ...filters,
    categories: ['wholesale'],
  }, propertiesPerPage);
  const {
    data: turnkeyData,
    isLoading: turnkeyIsLoading,
    fetchNextPage: turnkeyFetchNextPage,
    isFetchingNextPage: turnkeyIsFetchingNextPage,
    hasNextPage: turnkeyHasNextPage,
  } = useSearchProperties({
    ...filters,
    categories: ['turnkey'],
  }, propertiesPerPage);

  return {
    isLoading: internalIsLoading || newConstructionIsLoading || wholesaleIsLoading || turnkeyIsLoading,
    isFetchingNextPage: (
      mixedCategoryIsFetchingNextPage || internalIsFetchingNextPage
      || newConstructionIsFetchingNextPage || wholesaleIsFetchingNextPage || turnkeyIsFetchingNextPage
    ),
    mixedCategories: {
      data: mixedCategoryData,
      isLoading: mixedCategoryIsLoading,
      fetchNextPage: mixedCategoryFetchNextPage,
      hasNextPage: mixedCategoryHasNextPage,
      isFetchingNextPage: mixedCategoryIsFetchingNextPage,
    },
    internal: {
      data: internalData,
      isLoading: internalIsLoading,
      fetchNextPage: internalFetchNextPage,
      hasNextPage: internalHasNextPage,
      isFetchingNextPage: internalIsFetchingNextPage,
    },
    newConstruction: {
      data: newConstructionData,
      isLoading: newConstructionIsLoading,
      fetchNextPage: newConstructionFetchNextPage,
      isFetchingNextPage: newConstructionIsFetchingNextPage,
      hasNextPage: newConstructionHasNextPage,
    },
    turnkey: {
      data: turnkeyData,
      isLoading: turnkeyIsLoading,
      fetchNextPage: turnkeyFetchNextPage,
      isFetchingNextPage: turnkeyIsFetchingNextPage,
      hasNextPage: turnkeyHasNextPage,
    },
    wholesale: {
      data: wholesaleData,
      isLoading: wholesaleIsLoading,
      fetchNextPage: wholesaleFetchNextPage,
      isFetchingNextPage: wholesaleIsFetchingNextPage,
      hasNextPage: wholesaleHasNextPage,
    },
  };
};

const Listings = ({
  filters,
  setFilters,
  setPurchaseQuestionnaireDialogOpen,
  filtersCleared,
  filtersClearedWithoutCategories,
}: {
  filters: Filters,
  filtersCleared: boolean,
  filtersClearedWithoutCategories: boolean,
  setFilters: (filters: Partial<SettableFilters>) => void,
  setPurchaseQuestionnaireDialogOpen: (v: boolean) => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const analytics = useAnalytics();
  const { value: showGrossYieldAsDollar, loading: loadingGrossYieldAsDollarFF } = useListingCardGrossYieldAsDollar();

  const [welcomeToMarketplaceDialogOpen, setWelcomeToMarketplaceDialogOpen] = useState<boolean>(false);
  const [viewedWelcomeToMarketplaceScreen, setViewedWelcomeToMarketplaceScreen] = useState<boolean>(false);
  const propertiesPerPage = isMobile ? 8 : 18;

  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const {
    isLoading: isLoadingCategories,
    isFetchingNextPage,
    ...fetchData
  } = useFetchAllCategories(filters, propertiesPerPage, filtersCleared);

  const isLoading = isLoadingFeatures || isLoadingCategories || loadingGrossYieldAsDollarFF;

  const { user } = useAuth();

  const {
    data: userActionsData,
  } = useGetUserActions(user?.id ?? '');

  const { mutateAsync: updateUserActions } = useMutateUserActions();

  useEffect(() => {
    analytics.identify(user!.realID, {
      showGrossYieldAsDollar,
    }, features, !!user?.actAs);
  }, [showGrossYieldAsDollar, features]);

  useEffect(() => {
    if (!user?.isPm
      && !features.isFreemium
      && userActionsData?.viewedWelcomeToMarketplaceScreen === false
      && !welcomeToMarketplaceDialogOpen
      && !viewedWelcomeToMarketplaceScreen) {
      setWelcomeToMarketplaceDialogOpen(true);
      updateUserActions({ viewedWelcomeToMarketplaceScreen: true });
      setViewedWelcomeToMarketplaceScreen(true);
    }
  }, [userActionsData, welcomeToMarketplaceDialogOpen, viewedWelcomeToMarketplaceScreen]);

  const mixedCategoryProperties = getListingsFromPages(fetchData.mixedCategories.data?.pages ?? []);
  const internalProperties = getListingsFromPages(fetchData.internal.data?.pages ?? []);
  const newConstructionProperties = getListingsFromPages(fetchData.newConstruction.data?.pages ?? []);
  const turnkeyProperties = getListingsFromPages(fetchData.turnkey.data?.pages ?? []);
  const wholesaleProperties = getListingsFromPages(fetchData.wholesale.data?.pages ?? []);

  const changeExpandedCategory = (cat: Category) => {
    setFilters({ categories: toggleCategory(filters.categories ?? [], cat) });
  };

  const getCategoryLabel = (category: Category) => {
    switch (category) {
      case 'internal':
        return l.exclusive;
      case 'new_construction':
        return l.newConstruction;
      case 'turnkey':
        return l.turnkey;
      case 'wholesale':
        return l.wholesale;
      default:
        return '';
    }
  };

  type CategoryAndListings = {
    category: Category,
    properties: ListingProperty[],
  };

  const singleAvailableCategory: Category | null = useMemo(() => {
    const categoryAndListings: CategoryAndListings[] = [
      { category: 'internal', properties: internalProperties },
      { category: 'new_construction', properties: newConstructionProperties },
      { category: 'turnkey', properties: turnkeyProperties },
      { category: 'wholesale', properties: wholesaleProperties },
    ];

    const filtered = categoryAndListings.filter((c) => c.properties.length > 0);

    if (!isLoadingCategories && filtersCleared && filtered.length === 1) {
      return filtered[0].category;
    }

    return null;
  }, [
    filtersCleared,
    internalProperties,
    fetchData.internal,
    newConstructionProperties,
    fetchData.newConstruction,
    turnkeyProperties,
    fetchData.turnkey,
    wholesaleProperties,
    fetchData.wholesale,
    isLoadingCategories,
  ]);

  const shouldUseClassName = (category: Category) => {
    // use the class name only for the first category with data

    if (isLoading) return false;

    switch (category) {
      case 'internal':
        return !!internalProperties.length;
      case 'new_construction':
        return !!newConstructionProperties.length && !internalProperties.length;
      case 'turnkey':
        return !!turnkeyProperties.length && !internalProperties.length && !newConstructionProperties.length;
      case 'wholesale':
        return (
          !!wholesaleProperties.length
          && !internalProperties.length
          && !newConstructionProperties.length
          && !turnkeyProperties.length
        );
      default:
        return false;
    }
  };

  const categoryToProps: Record<Category, Omit<CategoryListingsProps, 'forceLoading'>> = {
    internal: {
      categoryLabel: getCategoryLabel('internal'),
      properties: internalProperties,
      isLoading: fetchData.internal.isLoading,
      changeExpandedCategory: () => changeExpandedCategory('internal'),
      showExpandButton: internalProperties.length > 3,
      filtersCleared,
      forceHide: !!singleAvailableCategory,
      useClassName: shouldUseClassName('internal'),
    },
    new_construction: {
      categoryLabel: getCategoryLabel('new_construction'),
      properties: newConstructionProperties,
      isLoading: fetchData.newConstruction.isLoading,
      changeExpandedCategory: () => changeExpandedCategory('new_construction'),
      showExpandButton: newConstructionProperties.length > 3,
      filtersCleared,
      forceHide: !!singleAvailableCategory,
      useClassName: shouldUseClassName('new_construction'),
    },
    turnkey: {
      categoryLabel: getCategoryLabel('turnkey'),
      properties: turnkeyProperties,
      isLoading: fetchData.turnkey.isLoading,
      changeExpandedCategory: () => changeExpandedCategory('turnkey'),
      showExpandButton: turnkeyProperties.length > 3,
      filtersCleared,
      forceHide: !!singleAvailableCategory,
      useClassName: shouldUseClassName('turnkey'),
    },
    wholesale: {
      categoryLabel: getCategoryLabel('wholesale'),
      properties: wholesaleProperties,
      isLoading: fetchData.wholesale.isLoading,
      changeExpandedCategory: () => changeExpandedCategory('wholesale'),
      showExpandButton: wholesaleProperties.length > 3,
      filtersCleared,
      forceHide: !!singleAvailableCategory,
      useClassName: shouldUseClassName('wholesale'),
    },
  };

  const handleMixedCategoryChange = () => {
    setFilters({ categories: [] });
  };

  const hasMixedCategoriesSelected = (filters.categories?.length ?? 0) > 1;
  const oneCategorySelected = (filters.categories?.length ?? 0) === 1;
  const defaultProps = oneCategorySelected ? categoryToProps[filters.categories![0]] : categoryToProps.internal;

  const mixedCategoryProps: Omit<ExpandableListingProps, 'forceLoading'> = {
    label: (hasMixedCategoriesSelected || !filtersClearedWithoutCategories) ? '' : defaultProps.categoryLabel,
    showHeader: !hasMixedCategoriesSelected && filtersClearedWithoutCategories,
    properties: mixedCategoryProperties,
    isLoading: fetchData.mixedCategories.isLoading,
    isFetchingNextPage: fetchData.mixedCategories.isFetchingNextPage,
    fetchNextPage: fetchData.mixedCategories.fetchNextPage,
    changeExpandedCategory: hasMixedCategoriesSelected ? handleMixedCategoryChange : defaultProps.changeExpandedCategory,
    showExpandButton: hasMixedCategoriesSelected ? false : defaultProps.properties.length > 3,
    expanded: !!singleAvailableCategory || !filtersCleared,
    hasNextPage: fetchData.mixedCategories.hasNextPage,
  };

  type NextPageProps = { isFetchingNextPage: boolean, hasNextPage: boolean, fetchNextPage: () => void };

  const categoryToNextPageProps: Record<Category, NextPageProps> = {
    internal: {
      isFetchingNextPage: fetchData.internal.isFetchingNextPage,
      hasNextPage: !!fetchData.internal.hasNextPage,
      fetchNextPage: fetchData.internal.fetchNextPage,
    },
    new_construction: {
      isFetchingNextPage: fetchData.newConstruction.isFetchingNextPage,
      hasNextPage: !!fetchData.newConstruction.hasNextPage,
      fetchNextPage: fetchData.newConstruction.fetchNextPage,
    },
    turnkey: {
      isFetchingNextPage: fetchData.turnkey.isFetchingNextPage,
      hasNextPage: !!fetchData.turnkey.hasNextPage,
      fetchNextPage: fetchData.turnkey.fetchNextPage,
    },
    wholesale: {
      isFetchingNextPage: fetchData.wholesale.isFetchingNextPage,
      hasNextPage: !!fetchData.wholesale.hasNextPage,
      fetchNextPage: fetchData.wholesale.fetchNextPage,
    },
  };

  const singleAvailableCategoryProps: Omit<ExpandableListingProps, 'forceLoading'> | null = singleAvailableCategory && {
    label: categoryToProps[singleAvailableCategory].categoryLabel,
    showHeader: true,
    properties: categoryToProps[singleAvailableCategory].properties,
    isLoading: categoryToProps[singleAvailableCategory].isLoading,
    isFetchingNextPage: categoryToNextPageProps[singleAvailableCategory].isFetchingNextPage,
    hasNextPage: categoryToNextPageProps[singleAvailableCategory].hasNextPage,
    fetchNextPage: categoryToNextPageProps[singleAvailableCategory].fetchNextPage,
    changeExpandedCategory: undefined,
    showExpandButton: false,
    expanded: true,
  };

  const allEmpty = Object.values(categoryToProps).every((c) => !c.properties.length);

  if (allEmpty && !isLoading && filtersCleared) {
    return (
      <Grid item xs={12}>
        <Stack alignItems="center" justifyContent="center">
          <Box my={3}>
            <Store height={40} width={40} />
          </Box>
          <Typography variant="h6">
            {l['listings.marketplaceIsEmptyTitle']}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {l['listings.marketplaceIsEmptyDescription']}
          </Typography>
        </Stack>
      </Grid>
    );
  }

  const totalProperties = (
    mixedCategoryProperties.length + internalProperties.length + newConstructionProperties.length + wholesaleProperties.length
  );

  // either nothing was found for all categories or nothing was found for the selected categories
  const noPropertiesFound = (
    (totalProperties === 0 && !isLoading && !isFetchingNextPage && !filters.categories?.length)
    || (filters.categories.length > 0 && mixedCategoryProperties.length === 0 && !fetchData.mixedCategories.isLoading)
  );

  return (
    <>
      <Grid
        container
        item
        rowGap={filters.categories ? 0 : 4}
        sx={{ transition: 'all 0.3s ease-in-out !important' }}
      >
        <ExpandableListings
          {...((singleAvailableCategory && singleAvailableCategoryProps) ? singleAvailableCategoryProps : mixedCategoryProps)}
          forceLoading={false}
        />
        {fetchData.internal.isLoading || internalProperties.length
          ? <CategoryListings {...categoryToProps.internal} forceLoading={isLoading} /> : null}
        {!isLoading && features.isFreemiumPM ? (
          <InviteOwnersBanner />
        ) : !isLoading && features.isInviteProspectsEnabled && (
          <InviteProspectBanner />
        )}
        {fetchData.newConstruction.isLoading || newConstructionProperties.length
          ? <CategoryListings {...categoryToProps.new_construction} forceLoading={isLoading} /> : null}
        {fetchData.turnkey.isLoading || turnkeyProperties.length
          ? <CategoryListings {...categoryToProps.turnkey} forceLoading={isLoading} /> : null}
        {fetchData.wholesale.isLoading || wholesaleProperties.length
          ? <CategoryListings {...categoryToProps.wholesale} forceLoading={isLoading} /> : null}
        {noPropertiesFound ? (
          <Grid item xs={12}>
            <Stack alignItems="center" mt={10} p={4}>
              <Box my={3}>
                <Store height={40} width={40} />
              </Box>
              <Typography variant="h6">
                {l['listings.noMatchesTitle']}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {l['listings.noMatchesDescription']}
              </Typography>
            </Stack>
          </Grid>
        ) : null}
      </Grid>
      <WelcomeToMarketplaceDialog
        open={welcomeToMarketplaceDialogOpen}
        closeDialog={() => {
          setWelcomeToMarketplaceDialogOpen(false);
          setPurchaseQuestionnaireDialogOpen(true);
        }}
      />
    </>
  );
};

type FilterKey = keyof Filters;

const predefinedFiltersKey: FilterKey = 'predefinedFilters';
const minPriceKey: FilterKey = 'minPrice';
const maxPriceKey: FilterKey = 'maxPrice';
const minBedsKey: FilterKey = 'minBeds';
const minBathsKey: FilterKey = 'minBaths';
const bedsUseExactMatchKey: FilterKey = 'bedsUseExactMatch';
const sortByKey: FilterKey = 'sortBy';
const selectedSearchOptionsKey: FilterKey = 'selectedSearchOptions';
const categoriesKey: FilterKey = 'categories';

export const ListingsPage = () => {
  const l = useLabels();
  const theme = useTheme();

  const [searchParams, setSearchParamsOriginal] = useSearchParams({});
  const [purchaseQuestionnaireDialogOpen, setPurchaseQuestionnaireDialogOpen] = useState<boolean>(false);
  const { shouldDisplayReferralProgram } = useGetB2CReferralProgram();
  const navigate = useNavigate();

  const selectedOptionsFromParams = z.array(z.string());

  const parseStringArray = (jsonData: string) => {
    try {
      return selectedOptionsFromParams.parse(JSON.parse(jsonData));
    } catch (e) {
      console.error(e);
    }

    return [];
  };

  const filters = filterSchema.parse({
    [predefinedFiltersKey]: parseStringArray(searchParams.get(predefinedFiltersKey) ?? '[]'),
    [minPriceKey]: searchParams.get(minPriceKey),
    [maxPriceKey]: searchParams.get(maxPriceKey),
    [minBedsKey]: searchParams.get(minBedsKey),
    [minBathsKey]: searchParams.get(minBathsKey),
    [bedsUseExactMatchKey]: searchParams.get(bedsUseExactMatchKey),
    [sortByKey]: searchParams.get(sortByKey),
    [selectedSearchOptionsKey]: parseStringArray(searchParams.get(selectedSearchOptionsKey) ?? '[]'),
    [categoriesKey]: parseStringArray(searchParams.get(categoriesKey) ?? '[]'),
  });

  const filtersClearedWithoutCategories = (
    !filters.predefinedFilters.length
    && !filters.minPrice
    && !filters.maxPrice
    && !filters.minBeds
    && !filters.minBaths
    && !filters.bedsUseExactMatch
    && !filters.selectedSearchOptions.length
  );

  const filtersCleared = (
    filtersClearedWithoutCategories && !filters.categories.length
  );

  const isFilterRelevantForCount = (key: keyof SettableFilters) => {
    if (key === categoriesKey) {
      return filters.categories?.length > 0;
    }

    if (key === predefinedFiltersKey) {
      return filters.predefinedFilters?.length > 0;
    }

    return key in filters && key !== sortByKey && key !== selectedSearchOptionsKey;
  };

  const activeFilterCount = Array.from(searchParams.keys()).filter(
    (k) => isFilterRelevantForCount(k as keyof SettableFilters),
  ).length;

  const setSearchParams = (params: Partial<SettableFilters>) => {
    if (!params) return;

    const filtersFromSearchParams = {
      ...Object.fromEntries(searchParams.entries()),
    };

    Object.keys(params).forEach((key) => {
      const value = (
        key === predefinedFiltersKey || key === categoriesKey
          ? JSON.stringify(params[key])
          : params[key as keyof SettableFilters]?.toString()!
      );
      if (value) {
        filtersFromSearchParams[key] = value;
      } else if (filtersFromSearchParams[key]) {
        delete filtersFromSearchParams[key];
      }
    });

    setSearchParamsOriginal(filtersFromSearchParams);
  };

  const setSelectedOptions = (options: string[]) => {
    setSearchParams({
      selectedSearchOptions: JSON.stringify(options),
    });
  };

  const resetFilters = () => {
    setSearchParamsOriginal({});
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [headerHeight, setHeaderHeight] = useState(0);

  const headerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setHeaderHeight(node.clientHeight);
    }
    // these dependencies are necessary because otherwise the size will be fixed after the initial load
  }, [isMobile, filters.selectedSearchOptions]);

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          width: '100vw',
          top: { xs: 52, lg: 0 },
          left: 0,
          height: headerHeight,
          zIndex: -1,
          backgroundColor: theme.palette.background.paper,
        }}
      />
      <Stack px={3}>
        <Stack
          ref={headerRef}
          direction={{ xs: 'column-reverse', md: 'row' }}
          justifyContent="space-between"
          gap={{ xs: 3, md: 0 }}
          py={5}
          sx={{
            overflow: 'visible',
          }}
        >
          <Stack
            justifyContent="space-between"
            flexGrow={1}
          >
            {!isMobile && (
              <PageTitle title={l['listings.marketplace']} />
            )}
            <FiltersContainer
              filters={filters}
              setFilters={setSearchParams}
              resetFilters={resetFilters}
              setSelectedOptions={setSelectedOptions}
              activeFilterCount={activeFilterCount}
              usedFilters={Array.from(searchParams.keys()) as (keyof SettableFilters)[]}
            />
          </Stack>
          {shouldDisplayReferralProgram && (
            <Box maxWidth={{ xs: '100%', md: '50%' }} maxHeight={{ xs: 'auto', md: '144px' }}>
              <WantToSellPropertyWidget
                onClick={() => { navigate('/marketplace/sell-property'); }}
                height={140}
              />
            </Box>
          )}
          <Box maxWidth={{ xs: 'auto', md: '50%' }}>
            <BuyBox
              purchaseQuestionnaireDialogOpen={purchaseQuestionnaireDialogOpen}
              setPurchaseQuestionnaireDialogOpen={setPurchaseQuestionnaireDialogOpen}
            />
          </Box>
          {isMobile && (
            <BoldTypography variant="h6">
              {l['listings.marketplace']}
            </BoldTypography>
          )}
        </Stack>
        <Spacer spacing={3} />
        <Listings
          filters={filters}
          filtersCleared={filtersCleared}
          filtersClearedWithoutCategories={filtersClearedWithoutCategories}
          setFilters={setSearchParams}
          setPurchaseQuestionnaireDialogOpen={setPurchaseQuestionnaireDialogOpen}
        />
      </Stack>
    </>
  );
};
