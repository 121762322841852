/* eslint-disable no-await-in-loop */
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery } from '@tanstack/react-query';

import { RecurringTransactionPayload, TransactionPayload } from './types/transaction';
import { convertProperty } from './converters';
import {
  CreateMortgageRequest,
  CreatePurchaseHistoryRequest,
  RecurringSingleTransaction,
  RecurringTransaction,
  UpdateMortgageRequest,
  UpdatePurchaseHistoryRequest,
} from './types';
import { config } from '../../config/config';
import { createMutationHook } from '../../hooks/createMutationHook';
import { useAuth } from '../../hooks/useAuth';
import { QueryKey } from '../../types/enums';
import { Property, PropertyForUpdate } from '../../types/property';
import { queryGraphQL } from '../amplify';
import {
  DashboardProperty as APIDashboardProperty,
  ListDashboardPropertiesByPmQuery,
  ListDashboardPropertyByOwnerQuery,
} from '../API';
import { axiosWithPayloadContext, createHeaders } from '../axios';
import { listDashboardPropertiesByOwner, listDashboardPropertyIDsByPm } from '../graphql/queries';

export const useListOwnerProperties = (owner :string) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.PROPERTIES, owner], async (): Promise<{
    properties: Property[],
    deletedProperties: Property[],
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listDashboardPropertiesByOwner,
      authToken: token,
      variables: { owner },
    }) as GraphQLResult<ListDashboardPropertyByOwnerQuery>;

    if (!res.data?.listDashboardPropertyByOwner) {
      return { properties: [], deletedProperties: [] };
    }

    const filtered = res.data.listDashboardPropertyByOwner.items.filter(Boolean) as APIDashboardProperty[];
    const converted = filtered.map(convertProperty).sort((a, b) => a.displayName.localeCompare(b.displayName));

    return {
      properties: converted.filter((p) => !p.deleted),
      deletedProperties: converted.filter((p) => p.deleted),
    };
  }, { enabled: !!owner });
};

export const useGetOwnersToPropertyIDs = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery({
    queryKey: [QueryKey.OWNERS_TO_PROPERTY_IDS, user?.pm],
    queryFn: async (): Promise<Record<string, { id: string, owner: string }[]>> => {
      const token = await getAccessTokenSilently();

      let nextToken = null;
      const propertyIDs: Record<string, { id: string, owner: string }[]> = {};

      do {
        const res = await queryGraphQL({
          query: listDashboardPropertyIDsByPm,
          authToken: token,
          variables: {
            pm: user?.pm,
            nextToken,
          },
        }) as GraphQLResult<ListDashboardPropertiesByPmQuery>;

        const items = res.data?.listDashboardPropertiesByPm?.items || [];
        const filteredItems = items.filter(Boolean).map((p) => ({ id: p?.id!, owner: p?.owner! }));

        // Group by owner
        filteredItems.forEach((item) => {
          if (!propertyIDs[item.owner]) {
            propertyIDs[item.owner] = [];
          }
          propertyIDs[item.owner].push(item);
        });

        nextToken = res.data?.listDashboardPropertiesByPm?.nextToken;
      } while (nextToken);

      return propertyIDs;
    },
    enabled: !!user?.pm,
  });
};

const getRecurringExpenseHookByTransactionId = (url: string) => (id: string, isEnabled: boolean) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.RECURRING_EXPENSE, id], async () => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext<RecurringTransaction>({
      url: `${config.apiBaseURL}${url}/?byTransactionId=${id}`,
      method: 'GET',
      headers: createHeaders(token),
    });
  }, { enabled: isEnabled });
};

const createExpenseHookDelete = (url: string) => () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (id: string) => {
    const token = await getAccessTokenSilently();
    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}${url}/${id}`,
      method: 'DELETE',
      headers: createHeaders(token),
    });
  });
};

const deleteSingleRecurringExpenseHook = (url: string) => () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (recurringTransaction: RecurringSingleTransaction) => {
    const token = await getAccessTokenSilently();
    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}${url}/${recurringTransaction.recurringId}/transaction/${recurringTransaction.transactionId}`,
      method: 'DELETE',
      headers: createHeaders(token),
    });
  });
};

export const useCreatePurchaseHistory = createMutationHook<CreatePurchaseHistoryRequest>('/purchase-history', 'POST');
export const useUpdatePurchaseHistory = createMutationHook<UpdatePurchaseHistoryRequest>('/purchase-history', 'PUT');
export const useCreateMortgage = createMutationHook<CreateMortgageRequest>('/property-mortgages', 'POST');
export const useUpdateMortgage = createMutationHook<UpdateMortgageRequest>('/property-mortgages', 'PUT');
export const useDeleteMortgage = createExpenseHookDelete('/property-mortgages');
export const useCreateExpenseOneTime = createMutationHook<TransactionPayload>('/balance', 'POST');
export const useCreateExpenseRecurrent = createMutationHook<RecurringTransactionPayload>('/property-user-expenses', 'POST');
export const useUpdateExpense = createMutationHook<TransactionPayload>('/balance', 'PUT');
export const useUpdateRecurringExpense = createMutationHook<RecurringTransactionPayload>('/property-user-expenses', 'PUT');
export const useDeleteExpense = createExpenseHookDelete('/balance');
export const useDeleteSingleRecurringExpense = deleteSingleRecurringExpenseHook('/property-user-expenses');
export const useDeleteRecurringExpense = createExpenseHookDelete('/property-user-expenses');
export const useGetRecurringExpenseByTransactionId = getRecurringExpenseHookByTransactionId('/property-user-expenses');
export const useUpdateProperty = createMutationHook<PropertyForUpdate>('/dashboard-property', 'PUT');
