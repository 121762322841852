import { useEffect, useMemo, useState } from 'react';

import {
  AppDashboardProperty,
  AppUserActions, objectEntries,
  useAnalytics, useFeatures, useFetchMetrics, useGetPMPreferences, useListMarketAnalysis,
  useListOwnerProperties,
} from 'lib';
import { MdTrendingUp } from 'react-icons/md';
import { Options as ReactToPdfOptions, usePDF } from 'react-to-pdf';
import {
  Card, CardContent, Container, Divider, MenuItem, Select, Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AnnualReportHeaderBanner } from './AnnualReportHeaderBanner';
import { BackgroundMusicPlayer } from './BackgroundMusicPlayer';
import { LeadershipSummary } from './LeadershipSummary';
import { LegalDisclaimer } from './LegalDisclaimer';
import { LegislationOverview } from './LegislationOverview';
import { MarketAnalysis } from './MarketAnalysis';
import { AnnualReportPdf } from './pdf';
import { PinkDotBanner } from './PinkDotBanner';
import { PortfolioPerformance, PropertyWithMetrics } from './PortfolioPerformance';
import { PortfolioProjections } from './PortfolioProjections';
import { LightButton } from '../../button/styled';
import { Pdf } from '../../icons';
import { useLabels } from '../../lib/translations';
import { FallbackSpinner, SpinnerIcon } from '../../spinner';
import { BlurredComponent } from '../BlurredComponent';

const getFipsCount = (properties: AppDashboardProperty[]) => {
  const fipsMap: Record<string, number> = {};

  properties.forEach((property) => {
    const { fips } = property.address;
    if (fipsMap[fips]) {
      fipsMap[fips] += 1;
    } else {
      fipsMap[fips] = 1;
    }
  });

  return fipsMap;
};

export const getPropertyWithMostCommonFips = (properties: AppDashboardProperty[]) => {
  const counts = getFipsCount(properties);

  const fips = Object.keys(counts).sort().reduce((a, b) => (counts[a] > counts[b] ? a : b));

  return properties.find((p) => p.address.fips === fips);
};

const ExportPdfButton = ({ toPDF }: { toPDF: (options: ReactToPdfOptions) => void }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [exportingPdf, setExportingPdf] = useState(false);

  return (
    <LightButton
      disabled={exportingPdf}
      variant="outlined"
      size="small"
      startIcon={exportingPdf ? undefined : <Pdf />}
      sx={{
        border: `1px solid ${theme.palette.text.disabled} !important`,
        zIndex: 100,
      }}
      onClick={() => {
        setExportingPdf(true);

        const exportPdf = async () => {
          toPDF({ filename: 'page.pdf' });

          setTimeout(() => {
            setExportingPdf(false);
          }, 5000);
        };

        exportPdf().then(() => { }).catch(() => { });

        analytics.track('Button Clicked', {
          buttonName: 'Export Annual Report',
        });
      }}
    >
      {exportingPdf ? <SpinnerIcon size={20} /> : l['annualReport.export']}
    </LightButton>
  );
};

export const AnnualReport = ({
  owner,
  showHeader,
  userActions,
  inPreview = false,
  ownerFullName,
}: {
  owner: string,
  showHeader: boolean,
  userActions: AppUserActions | undefined,
  inPreview?: boolean,
  ownerFullName: string,
}) => {
  const l = useLabels();
  const { toPDF, targetRef } = usePDF({
    filename: 'Blanket Annual Report.pdf',
    resolution: 3,
    page: {
      format: 'letter',
    },
    canvas: {
      mimeType: 'image/jpeg',
      qualityRatio: 0.9,
    },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
        allowTaint: true,
      },
    },
  });

  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const { data: ownerProperties, isLoading } = useListOwnerProperties(owner);
  const { data: pmPreferences, isLoading: pmPreferencesLoading } = useGetPMPreferences();
  const properties = ownerProperties?.properties ?? [];
  const { data: metricsResult } = useFetchMetrics({
    properties: properties.map(({ id }) => id),
    pointInTime: new Date('01/01/2025').getTime() / 1000,
  }, !!properties.length, owner);
  const {
    data: marketAnalysisData,
    isLoading: isLoadingMarketAnalysis,
  } = useListMarketAnalysis(Object.keys(getFipsCount(properties)));
  const [selectedAreaName, setSelectedAreaName] = useState('');
  const { fips, state } = useMemo(() => {
    if (!selectedAreaName) {
      return { fips: '', state: '' };
    }

    const fipsCounts = getFipsCount(properties);

    const allFips: string[] = [];

    objectEntries(marketAnalysisData || {}).forEach(([fipsCode, marketData]) => {
      marketData.forEach((data) => {
        if (data.areaName === selectedAreaName) {
          allFips.push(fipsCode);
        }
      });
    });

    const relevantFips = Array.from(new Set(allFips)).sort((a, b) => fipsCounts[b] - fipsCounts[a]);

    const mostCommonFips = relevantFips[0];

    return {
      fips: mostCommonFips,
      state: properties.find((p) => p.address.fips === mostCommonFips)?.address.state ?? '',
    };
  }, [selectedAreaName, properties, marketAnalysisData]);

  const uniqueFipsWithAreaNames = Array.from(new Set(Object.keys(getFipsCount(properties)).map((fipsCode) => {
    const areaName = marketAnalysisData ? marketAnalysisData[fipsCode][0].areaName ?? '' : '';
    return areaName;
  }))).sort();

  let totalValue = 0;
  let totalEquity = 0;
  let totalAppreciation = 0;
  let totalCashflow = 0;
  const propertiesWithMetrics: PropertyWithMetrics[] = [];

  properties.forEach((property) => {
    if (!metricsResult) return;

    const responseMetrics = metricsResult.metrics[property.id];
    const isValuationAvailable = !!responseMetrics?.valuation;

    if (isValuationAvailable) {
      totalValue += responseMetrics?.valuation || 0;
      totalEquity += responseMetrics?.totalEquity || 0;
      totalAppreciation += responseMetrics?.appreciation || 0;
      totalCashflow += responseMetrics?.cashFlow || 0;
    }

    const isMetricsAvailable = !!responseMetrics
      && property.transactions.filter((t) => t.label !== 'estimated'
        && new Date(t.transactionDate).getTime() > new Date('01/01/2024').getTime()
        && new Date(t.transactionDate).getTime() < new Date('01/01/2025').getTime()).length > 0;

    propertiesWithMetrics.push({
      ...property,
      isMetricsAvailable,
      isValuationAvailable,
      metrics: responseMetrics?.metrics || property.metrics,
      propertyId: property.id,
      fullAddress: property.address.fullAddress,
    });
  });

  const selectComponent = () => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {uniqueFipsWithAreaNames?.length > 1 && (
        <Select
          size="small"
          placeholder="Select your property"
          value={selectedAreaName}
        >
          {uniqueFipsWithAreaNames?.map((fipsWithAreaName) => (
            <MenuItem
              value={fipsWithAreaName}
              key={fipsWithAreaName}
              onClick={() => {
                setSelectedAreaName(fipsWithAreaName);
              }}
            >
              {`${fipsWithAreaName}`}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );

  useEffect(() => {
    if (properties?.length > 0 && properties.every((p) => p.owner === owner) && marketAnalysisData) {
      const mostCommonProperty = getPropertyWithMostCommonFips(properties);

      setSelectedAreaName(marketAnalysisData[mostCommonProperty!.address.fips][0]?.areaName ?? '');
    }
  }, [properties, owner, ownerFullName, marketAnalysisData]);

  if (isLoading || pmPreferencesLoading || isLoadingFeatures || isLoadingMarketAnalysis) {
    return <FallbackSpinner />;
  }

  if (!(features.isAnnualReportPmEnabled || features.isAnnualReportEnabled)) {
    return null;
  }

  if (properties?.length === 0) {
    return (
      <Stack height="100%" width="100%" justifyContent="center" alignItems="center">
        <Stack gap={3} p={3} alignItems="center">
          <MdTrendingUp size={36} />
          <Typography variant="h6">
            {l.propertiesNotFound}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack height="100%" width="100%" gap={4} p={4}>
      <Container>
        <Stack gap={3} paddingBottom={5}>
          {showHeader && (
            <Stack direction="row" justifyContent="flex-end" gap={2}>
              <BackgroundMusicPlayer />
              <ExportPdfButton toPDF={toPDF} />
            </Stack>
          )}
          <AnnualReportHeaderBanner ownerName={ownerFullName} />
          <Card
            elevation={1}
            component={Stack}
            sx={{
              justifyContent: 'space-between',
              p: 0,
              gap: 4,
            }}
          >
            <CardContent>
              <Stack gap={4} divider={<Divider />}>
                <Stack gap={4}>
                  <Divider />
                  <MarketAnalysis fips={fips} selectComponent={selectComponent()} />
                </Stack>
                <PinkDotBanner />
                <LegislationOverview state={state} />
                <BlurredComponent
                  title=""
                  subtitle={l['annualReport.noDataForThisSection']}
                  isBlurred={(!(propertiesWithMetrics.filter((p) => p.isValuationAvailable).length > 0))
                      || (!(propertiesWithMetrics.filter((p) => p.isMetricsAvailable).length > 0))}
                >
                  <PortfolioPerformance
                    owner={owner}
                    properties={propertiesWithMetrics}
                    inPreview={inPreview}
                    totalValue={totalValue}
                    totalEquity={totalEquity}
                    totalAppreciation={totalAppreciation}
                    totalCashflow={totalCashflow}
                  />
                  <PortfolioProjections
                    userActions={userActions}
                    pmPreferences={pmPreferences || undefined}
                    ownerProperties={ownerProperties?.properties ?? []}
                  />
                </BlurredComponent>
              </Stack>
            </CardContent>
            <Stack>
              <LeadershipSummary />
              <LegalDisclaimer />
            </Stack>
          </Card>
        </Stack>
      </Container>
      {!inPreview && selectedAreaName && (
        <AnnualReportPdf
          targetRef={targetRef}
          owner={owner}
          areaNameWithFips={
            {
              fips,
              state,
              areaName: selectedAreaName,
            }
          }
        />
      )}
    </Stack>
  );
};
