import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';

import { convertLegislationData, convertMarketData } from './converters';
import {
  CreateDashboardMetricsRequest, LegislationItem, MarketAnalysisData,
} from './types';
import { createMutationHook } from '../../hooks/createMutationHook';
import { useAuth } from '../../hooks/useAuth';
import { filterNulls } from '../../lib/filter';
import { QueryKey } from '../../types/enums';
import { queryGraphQL } from '../amplify';
import {
  LegislationData,
  ListLegislationDataByStateQuery,
  MarketData,
} from '../API';
import { listLegislationItems } from '../graphql/queries';

interface CustomListMarketDataByFIPSQuery {
  [key: string]: {
    items: MarketData[];
  };
}

export const useListLegislationData = (state: string) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.LEGISLATION_ITEMS, state], async (): Promise<LegislationItem[]> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listLegislationItems,
      authToken: token,
      variables: { state },
    }) as GraphQLResult<ListLegislationDataByStateQuery>;

    if (!res.data?.listLegislationDataByState) {
      return [];
    }

    const legislationItems = filterNulls<LegislationData>(
      res.data.listLegislationDataByState.items,
    ).map(convertLegislationData);

    return (legislationItems);
  }, { enabled: !!state });
};

export const useListMarketAnalysisFiltered = (fips: string) => {
  const { data, isLoading } = useListMarketAnalysis([fips]);
  return { data: data?.[fips] ?? [], isLoading };
};

export const useListMarketAnalysis = (fips: string[]) => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.MARKET_ANALYSIS, ...fips], async (): Promise<Record<string, MarketAnalysisData[]>> => {
    const token = await getAccessTokenSilently();

    if (!fips || fips.length === 0 || !fips[0]) {
      return {};
    }

    const uniqueFips = Array.from(new Set(fips));

    const query = `
      query ListMarketDataByFIPS {
        ${uniqueFips.map((fipsCode) => `
          fips_${fipsCode}: listMarketDataByFIPS(fips: "${fipsCode}") {
            items {
              areaName
              createdTime
              dataType
              date
              daysOnMarket
              fips
              homeValueAll
              homeValueSFR
              marketHeat
              marketSummary
              rentAll
              rentSFR
            }
          }
        `).join('')}
      }
    `;

    const res = await queryGraphQL({
      query,
      authToken: token,
      variables: { owner: user?.id ?? '' },
    }) as { data: CustomListMarketDataByFIPSQuery };

    const marketDataByFips: Record<string, MarketAnalysisData[]> = {};

    uniqueFips.forEach((fipsCode) => {
      const data = res.data ? res.data[`fips_${fipsCode}`] : null;
      if (data && data.items) {
        marketDataByFips[fipsCode] = data.items.map(convertMarketData);
      }
    });

    return marketDataByFips;
  }, { enabled: !!user?.id && !!fips && fips.length > 0 && fips[0] !== 'fips_' });
};

export const useCreateMetrics = createMutationHook<CreateDashboardMetricsRequest>('/property-metrics', 'POST');

export const useFetchMetrics = (payload: CreateDashboardMetricsRequest, isEnabled: boolean, userId:string) => {
  const createMetrics = useCreateMetrics();
  return useQuery({
    queryKey: [QueryKey.FETCH_METRICS, userId],
    queryFn: async () => {
      const result = await createMetrics.mutateAsync(payload);
      return result.data;
    },
    enabled: isEnabled,
  });
};
