import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  sum, useAnalytics, useFeatures,
  useGetUsers,
  useIsLegacyPM,
} from 'lib';
import {
  ActivatedOwner,
  Banner3,
  BasicMetric,
  BlurredComponent, BoldTypography,
  BuyerLeads,
  DetailedMetric,
  DollarCircle,
  FallbackSpinner,
  formatDecimalToPercentage,
  formatNumberToCurrency,
  HubSpotCalendar,
  InfoTooltip,
  InvitedOwner,
  PageTitle,
  PercentRedBig,
  SellerLeads,
  Spacer,
  useLabels,
} from 'ui';
import {
  Grid, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import { CommsFromBlanket } from './components/CommsFromBlanket';
import { MonthlyActiveUsers } from './components/MonthlyActivatedUsers';
import { RevenueAtRisk } from './components/RevenueAtRisk';
import { RevenueOpportunities } from './components/RevenueOpportunities';
import { TotalUsers } from './components/TotalUsers';
import { Product } from './types';
import { useGetPmMetrics } from '../../api/pm-metrics';
import { useListPropertyValuations } from '../../api/properties';
import { useCountBuyerLeads, useCountSellerLeads } from '../../api/workflows';

const LegacyBlanketOverview = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const { data: owners, isLoading: isLoadingOwners } = useGetUsers(['owner']);
  const { data: prospects, isLoading: isLoadingProspects } = useGetUsers(['prospect_user']);
  const { data: buyerLeadCountPages, isLoading: isLoadingBuyerLeadCount } = useCountBuyerLeads();
  const { data: sellerLeadCountPages, isLoading: isLoadingSellerLeadCount } = useCountSellerLeads();
  const { data: valuations, isLoading: isLoadingValuations } = useListPropertyValuations();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const activatedProspects = prospects?.filter((prospect) => prospect.isActivated) || [];
  const activatedOwners = owners?.filter((owner) => owner.isActivated) || [];

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (
    isLoadingOwners
    || isLoadingBuyerLeadCount
    || isLoadingSellerLeadCount
    || isLoadingValuations
    || isLoadingProspects
    || isLoadingFeatures
  ) return null;

  const buyerLeadCount = buyerLeadCountPages?.pages.reduce((acc, page) => acc + page.count, 0);
  const sellerLeadCount = sellerLeadCountPages?.pages.reduce((acc, page) => acc + page.count, 0);
  const portfolioValue = sum(valuations?.pages.map((page) => sum(page.valuations)) ?? []);

  return (
    <Stack p={3}>
      <PageTitle title={l['menu-blanket-overview']} />
      <Spacer spacing={3} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack
            sx={{
              background: theme.palette.primary.main,
              borderRadius: '6px',
              p: 4,
              height: '100%',
            }}
            gap={5}
          >
            <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
              <BoldTypography variant="h6" color="primary.contrastText">
                {l.portfolioValue}
              </BoldTypography>
              <InfoTooltip
                title={l['pm-dashboard.blanket-overview.portfolioValue.tooltip']}
                arrow
                isLight
                isOutlined
                buttonSx={{ color: theme.palette.primary.contrastText }}
                track={(value) => {
                  analytics.track('Tooltip Toggled', {
                    value,
                    tooltipName: 'Portfolio Value Tooltip',
                  });
                }}
              />
            </Stack>
            <Stack direction="row" gap={2} alignItems="center" color="primary.contrastText">
              <DollarCircle />
              <BoldTypography variant="h6" color="primary.contrastText">
                {formatNumberToCurrency(portfolioValue, 0)}
              </BoldTypography>
            </Stack>
          </Stack>
        </Grid>
        {features.isPmLeadsViewEnabled && (
          isMobile ? (
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={6}>
                <BasicMetric label={l.buyerLeads} value={buyerLeadCount} icon={<BuyerLeads />} />
              </Grid>
              <Grid item xs={6}>
                <BasicMetric label={l.sellerLeads} value={sellerLeadCount} icon={<SellerLeads />} />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={4}>
                <BasicMetric label={l.buyerLeads} value={buyerLeadCount} icon={<BuyerLeads />} />
              </Grid>
              <Grid item xs={12} md={4}>
                <BasicMetric label={l.sellerLeads} value={sellerLeadCount} icon={<SellerLeads />} />
              </Grid>
            </>
          )
        )}
        <Grid item xs={12}>
          <DetailedMetric
            title={l.numberOfOwners}
            tooltipContent={l['pm-dashboard.blanket-overview.owners.tooltip']}
            onClick={() => navigate('/admin/owners')}
            metrics={[
              {
                id: 'signed-up-owners',
                value: activatedOwners.length,
                label: l.activated,
                icon: <ActivatedOwner />,
              },
              {
                id: 'invited-owners',
                value: owners?.length,
                label: l.invited,
                icon: <InvitedOwner />,
              },
              {
                id: 'owners-conversion-rate',
                value: formatDecimalToPercentage(owners?.length ? activatedOwners.length / owners.length : 0, 2),
                label: l['pm-dashboard.owner.conversion-rate'],
                icon: <PercentRedBig />,
              },
            ]}
          />
        </Grid>
        {features.isMarketplaceEnabled && (
          <Grid item xs={12}>
            <DetailedMetric
              title={l.numberOfProspects}
              tooltipContent={l['pm-dashboard.blanket-overview.prospects.tooltip']}
              onClick={() => navigate('/admin/prospects')}
              metrics={[
                {
                  id: 'signed-up-prospects',
                  value: activatedProspects.length,
                  label: l.activated,
                  icon: <ActivatedOwner />,
                },
                {
                  id: 'invited-prospects',
                  value: prospects?.length,
                  label: l.invited,
                  icon: <InvitedOwner />,
                },
                {
                  id: 'prospects-conversion-rate',
                  value: formatDecimalToPercentage(prospects?.length ? activatedProspects.length / prospects.length : 0, 2),
                  label: l['pm-dashboard.owner.conversion-rate'],
                  icon: <PercentRedBig />,
                },
              ]}
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

const NonLegacyBlanketOverview = () => {
  const l = useLabels();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const [scheduleMeetingDialogOpen, setScheduleMeetingDialogOpen] = useState(false);
  const { isLoading: isLoadingMetrics, data: metrics } = useGetPmMetrics();
  const { isLoading, ...features } = useFeatures();
  const cardHeight = 240;

  const handleClickUnlock = (product: Product) => {
    analytics.track('Button Clicked', {
      buttonName: 'Unlock',
      product,
    });

    setScheduleMeetingDialogOpen(true);
  };

  const handleClickReportBanner = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Annual Report',
    });

    navigate('/pm/contacts');
  };

  if (isLoading || isLoadingMetrics) return <FallbackSpinner />;

  return (
    <>
      <Stack gap={4} px={3} pb={3}>
        <Stack gap={3}>
          <BoldTypography variant="h6">{l.general}</BoldTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} sx={{ maxHeight: cardHeight }}>
              <TotalUsers metrics={metrics!} />
            </Grid>
            <Grid item xs={12} sm={6} md={3.5} sx={{ maxHeight: cardHeight }}>
              <MonthlyActiveUsers metrics={metrics!} />
            </Grid>
            <Grid item xs={12} sm={6} md={3.5} sx={{ maxHeight: cardHeight }}>
              <CommsFromBlanket metrics={metrics!} />
            </Grid>
          </Grid>
        </Stack>
        <Stack gap={3}>
          <BoldTypography variant="h6">{l.retain}</BoldTypography>
          <BlurredComponent
            isBlurred={!features.isRetainProductEnabled}
            title={l['blanketOverview.unlock.retainTitle']}
            subtitle={l['blanketOverview.unlock.retainSubtitle']}
            onClickAction={() => handleClickUnlock('retain')}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <RevenueAtRisk metrics={metrics!} />
              </Grid>
              {/* this is just to entice the user to click unlock */}
              {!features.isRetainProductEnabled && (
                <>
                  <Grid item xs={12} sm={6} md={3.5} sx={{ maxHeight: cardHeight }}>
                    <MonthlyActiveUsers metrics={metrics!} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3.5} sx={{ maxHeight: cardHeight }}>
                    <CommsFromBlanket metrics={metrics!} />
                  </Grid>
                </>
              )}
            </Grid>
            {features.isAnnualReportPmEnabled && (
              <Grid item xs={12}>
                <Banner3
                  title={l['annualReport.pm.title']}
                  subtitle={l['annualReport.pm.subtitle']}
                  buttonText={l['annualReport.pm.button']}
                  onClick={handleClickReportBanner}
                />
              </Grid>
            )}
          </BlurredComponent>
        </Stack>
        <Stack gap={3}>
          <BoldTypography variant="h6">{l.grow}</BoldTypography>
          <BlurredComponent
            isBlurred={!features.isGrowProductEnabled}
            title={l['blanketOverview.unlock.growTitle']}
            subtitle={l['blanketOverview.unlock.growSubtitle']}
            onClickAction={() => handleClickUnlock('grow')}
          >
            <Grid container>
              <Grid item xs={12} md={5}>
                <RevenueOpportunities metrics={metrics!} />
              </Grid>
              {/* this is just to entice the user to click unlock */}
              {!features.isGrowProductEnabled && (
                <>
                  <Grid item xs={12} sm={6} md={3.5} sx={{ maxHeight: cardHeight }}>
                    <MonthlyActiveUsers metrics={metrics!} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3.5} sx={{ maxHeight: cardHeight }}>
                    <CommsFromBlanket metrics={metrics!} />
                  </Grid>
                </>
              )}
            </Grid>
          </BlurredComponent>
        </Stack>
      </Stack>
      <HubSpotCalendar open={scheduleMeetingDialogOpen} onClose={() => setScheduleMeetingDialogOpen(false)} />
    </>
  );
};

export const BlanketOverview = () => {
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPM();

  if (loadingIsLegacyPM) return <FallbackSpinner />;

  return isLegacyPM ? <LegacyBlanketOverview /> : <NonLegacyBlanketOverview />;
};
