import { useEffect } from 'react';
import {
  useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';

import {
  Claims,
  Features,
  firstLoad, impersonationToken, useAuth,
  useFeatures,
  User,
} from 'lib';

import { analytics } from '../lib/3rd-parties/analytics';
import { redirectToRelativeHomepage } from '../lib/redirect';

const setIdForThirdParties = (claims: Claims | undefined, user: User | undefined, features: Omit<Features, 'isLoading'>) => {
  if (claims && user) {
    analytics.identify(user.realID, {
      pm: user.pm,
      given_name: user.givenName,
      family: user.familyName,
      email: user.email,
      name: user.name,
      picture: user.picture,
      isActAs: claims.isActAs,
    }, features, user.actAs);
    analytics.register({ isActAs: claims.isActAs });
  }
};

export const usePostLogin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const features = useFeatures();

  const {
    user,
    isAuthenticated,
    getIdTokenClaims,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (searchParams.get(impersonationToken)) {
      sessionStorage.setItem(impersonationToken, searchParams.get(impersonationToken) ?? '');
      setSearchParams('');
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isAuthenticated) return;

    analytics.page();
  }, [location.pathname, isAuthenticated]);

  useEffect(() => {
    if (firstLoad.value && isAuthenticated && user && !features.isLoading && features) {
      analytics.enrich(user, features);

      getIdTokenClaims().then((claims) => {
        firstLoad.value = false;
        setIdForThirdParties(claims, user, features);
        if (location.pathname === '/') {
          redirectToRelativeHomepage(navigate, claims, user.pm, features);
        }
      }).catch(console.error);

      if (searchParams.get('fromEmail')) {
        analytics.track('Link Clicked', {
          linkName: 'Email LInk',
          subject: searchParams.get('fromEmail'),
        });
        setSearchParams('');
      }

      if (searchParams.get('s')) {
        analytics.register({
          source: searchParams.get('s'),
        });
        setSearchParams('');
      }
    }
  }, [
    isAuthenticated,
    user,
    location,
    firstLoad,
    features,
    location.pathname,
  ]);

  useEffect(() => {
    // @ts-ignore
    window.Intercom?.('onShow', () => {
      analytics.track('Intercom Toggled', {
        value: true,
      });
    });

    // @ts-ignore
    window.Intercom?.('onHide', () => {
      analytics.track('Intercom Toggled', {
        value: false,
      });
    });
  }, []);

  useEffect(() => {
    if (user) {
      import('@sentry/react').then(async (Sentry) => {
        Sentry.setContext('user', user);
      });
    }
  }, [user?.email]);
};
