import { SatisfactionLevel } from 'lib';
import { signal } from '@preact/signals-react';

export enum RetainTab {
  NEW = 'new',
  IN_PROGRESS = 'in-progress',
  RESOLVED = 'resolved',
  NO_RISK = 'no-risk',
}

export const currentRetainTab = signal<RetainTab | null>(null);

// eslint-disable-next-line no-spaced-func
export const retentionPolicyDialogState = signal<{ open: boolean, openDialog: () => void }>({
  open: false,
  openDialog: () => { },
});

export const showOwnerRiskMovedToInProgressToast = signal(false);

type OwnerSentimentDialogState = {
  openDialog: () => void,
  ownerEmail: string | null,
  ownerRiskID: string | null,
  satisfactionLevel: SatisfactionLevel | null,
  afterSubmit: () => Promise<void>,
};

export const ownerSentimentDialogState = signal<OwnerSentimentDialogState>({
  openDialog: () => { },
  ownerEmail: null,
  ownerRiskID: null,
  satisfactionLevel: null,
  afterSubmit: () => Promise.resolve(),
});
