import { ReactNode, useEffect, useState } from 'react';

import {
  APIUser,
  QueryKey, useAnalytics, useAuth, useGetPMSettings, useUpdatePmSettings,
} from 'lib';
import { BiSolidPencil } from 'react-icons/bi';
import { toast } from 'react-toastify';
import {
  annualReportLeadershipSummaryState,
  BoldTypography, InfoTooltip,
  InvertedButtonDark, useLabels,
} from 'ui';
import {
  Stack, Typography, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { LeadershipSummaryDialog } from './LeadershipSummaryDialog';

type MetricData = {
  id: string,
  value: ReactNode,
  label: string,
  icon: ReactNode,
};

export const ReportAnnualInvitationMetric = ({
  title,
  tooltipContent = null,
  metrics,
  users,
}: {
  title: string,
  tooltipContent?: ReactNode,
  metrics: MetricData[],
  users: APIUser[],

}) => {
  const theme = useTheme();
  const analytics = useAnalytics();
  const l = useLabels();
  const [leadershipSummaryDialogOpen, setLeadershipSummaryDialogOpen] = useState<boolean>(false);
  const { mutateAsync: updatePmSettings, isLoading: isUpdatingPmSettings } = useUpdatePmSettings();
  const queryClient = useQueryClient();
  const { user: loggedInUser } = useAuth();
  const { data: pmSettings } = useGetPMSettings();
  const [initialSummary, setInitialSummary] = useState('');

  const onSave = async (summary: string) => {
    try {
      await updatePmSettings({ leadershipSummary2024: summary });
      queryClient.invalidateQueries([QueryKey.PM_SETTINGS, loggedInUser?.pm]);
      toast.success(l['owner.successMessageSaveSummary']);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }

    setLeadershipSummaryDialogOpen(false);
  };

  useEffect(() => {
    annualReportLeadershipSummaryState.value = pmSettings?.leadershipSummary2024 ?? '';
    setInitialSummary(pmSettings?.leadershipSummary2024 ?? '');

    return () => {
      annualReportLeadershipSummaryState.value = '';
    };
  }, [pmSettings?.leadershipSummary2024]);

  return (
    <Stack
      sx={{
        background: theme.palette.primary.main,
        p: 4,
        width: '100%',
        borderRadius: '6px',
        border: `1px solid ${theme.palette.grey.A100}`,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      gap={3}
    >
      <LeadershipSummaryDialog
        users={users}
        onSave={onSave}
        isLoading={isUpdatingPmSettings}
        open={leadershipSummaryDialogOpen}
        onClose={() => setLeadershipSummaryDialogOpen(false)}
        initialSummary={initialSummary}
      />
      <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
        <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" width="100%" gap={2}>
          <BoldTypography variant="h6" color="primary.contrastText">
            {title}
          </BoldTypography>
          <InvertedButtonDark
            sx={{
              background: theme.palette.primary.main,
            }}
            type="button"
            onClick={() => {
              setLeadershipSummaryDialogOpen(true);
            }}
            startIcon={<BiSolidPencil />}
          >
            {!pmSettings?.leadershipSummary2024
              ? (
                <Typography color="primary.contrastText">{`${l['owner.LeadershipSummaryTitle']}`}</Typography>
              ) : (
                <Typography color="primary.contrastText">{`${l['owner.LeadershipSummaryTitleEdit']}`}</Typography>
              )}
          </InvertedButtonDark>
        </Stack>

        {tooltipContent && (
          <InfoTooltip
            title={tooltipContent}
            arrow
            isLight
            isOutlined
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Portfolio Value Tooltip',
              });
            }}
          />
        )}
      </Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        gap={3}
      >
        {metrics.map((metric) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            key={metric.id}
          >
            {metric.icon}
            <Stack direction={{ xs: 'row', md: 'column' }} alignItems={{ xs: 'center', md: 'flex-start' }}>
              <BoldTypography variant="h6" color="primary.contrastText">
                {metric.value}
              </BoldTypography>
              <Typography variant="body2" color="primary.contrastText">
                {metric.label}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
