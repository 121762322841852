import {
  useEffect, useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  APIUser,
  Invitation, pmState,
  QueryKey,
  Role, useAuth,
  useFeatures, useGetAnnualInvitationReport,
  useGetPMSettings,
  useGetUsers, useInviteAnnualReportOwners,
} from 'lib';
import { GoGraph } from 'react-icons/go';
import { toast } from 'react-toastify';
import {
  ActivatedOwner, AdminView, DetailedMetric, formatDecimalToPercentage,
  formatPotentialTodayDate,
  InvertedButton, LightTooltip, PercentGreenBig,
  PercentGreenBox, PersonArrowDown, useLabels,
  YesNoDialog,
} from 'ui';
import {
  Box,
  Checkbox,
  Grid,
  Stack,
  Typography,
  useMediaQuery, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  ColumnDef,
  createColumnHelper,
  Row,
} from '@tanstack/react-table';

import {
  TableAction,
} from './buttons';
import { ReportAnnualInvitationMetric } from './ReportAnnualInvitationMetric';
import { useGetNotificationTemplate } from '../../api/notification-templates';

const sortByActivated = (a: APIUser, b: APIUser) => {
  if (a.isActivated && !b.isActivated) {
    return 1;
  }

  if (!a.isActivated && b.isActivated) {
    return -1;
  }

  return 0;
};

const sortByIsOwner = (a: APIUser, b: APIUser) => {
  const aHasOwnerRole = a.roles.includes(Role.OWNER);
  const bHasOwnerRole = b.roles.includes(Role.OWNER);

  if (aHasOwnerRole && !bHasOwnerRole) {
    return 1;
  }

  if (!aHasOwnerRole && bHasOwnerRole) {
    return -1;
  }

  return 0;
};

const sortColumnByActivated = (a: Row<APIUser>, b: Row<APIUser>) => sortByActivated(a.original, b.original);

const sortColumnByIsOwner = (a: Row<APIUser>, b: Row<APIUser>) => sortByIsOwner(a.original, b.original);

export const Owners = ({ renderedOnRoot = true }: { renderedOnRoot?: boolean }) => {
  const theme = useTheme();
  const l = useLabels();
  const [searchParams] = useSearchParams();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [users, setUsers] = useState<APIUser[] | undefined>(undefined);
  const [selectAllUsers, setSelectAllUsers] = useState<boolean>(false);
  const { mutateAsync: inviteAnnualReportOwners, isLoading: isLoadingInviteAnnualReportOwners } = useInviteAnnualReportOwners();
  const [confirmReportSummaryOpen, setConfirmReportSummaryOpen] = useState(false);

  const { data: pmSettings } = useGetPMSettings();

  const queryClient = useQueryClient();
  const { user: loggedInUser } = useAuth();

  const {
    data,
    isLoading: isLoadingUsers,
    isError,
  } = useGetUsers(['owner', 'marketplace_user']);

  const {
    data: annualInvitationsData,
    isLoading: isLoadingAnnualInvitations,
  } = useGetAnnualInvitationReport();

  const isLoading = isLoadingUsers || isLoadingFeatures || isLoadingAnnualInvitations;

  useEffect(() => {
    if (!users && data && !isLoading) {
      setUsers(data);
    }
  }, [data]);

  const {
    data: inviteNotificationTemplate,
  } = useGetNotificationTemplate('invitation_user');

  const getSMSText = (user: APIUser) => {
    if (!inviteNotificationTemplate?.template) return '';

    return inviteNotificationTemplate.template
      .replace('{{ .PMCompanyName }}', pmState.value?.name || '')
      .replace('{{ .Name }}', user.name)
      .replace(
        '{{ .Link }}', '<a href="#">https://api.blankethomes.com/link</a>',
      ).replaceAll('\n', '<br />');
  };

  const invitationsMap = useMemo(() => {
    if (annualInvitationsData && !isLoadingAnnualInvitations) {
      const map = new Map<string, Invitation>(
        annualInvitationsData.map((invitation) => [invitation.email, invitation]),
      );

      return map;
    }

    return new Map<string, Invitation>();
  }, [users, inviteNotificationTemplate]);

  const owners = useMemo(() => {
    if (users && inviteNotificationTemplate) {
      return users?.sort(sortByActivated) as APIUser[];
    }

    return [];
  }, [users, inviteNotificationTemplate]);

  const activatedOwners = data?.filter((owner) => owner.isActivated) || [];

  const getInvitationById = (email: string): string => {
    const invitation = invitationsMap.get(email); // Get the value for the given key
    return invitation ? invitation.sentDate : ''; // Return the value or null if not found
  };

  const columnHelper = createColumnHelper<APIUser>();
  const columns = useMemo(() => {
    const cols: ColumnDef<APIUser, string>[] = [

      columnHelper.accessor('name', {
        header: l['pm-dashboard.owner.name'],
        enableSorting: true,
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
      columnHelper.accessor('email', {
        header: l['pm-dashboard.owner.email'],
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
      columnHelper.accessor('phoneNumber', {
        header: l['pm-dashboard.owner.phone'],
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
    ];

    const selectOwners = (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setSelectAllUsers(isChecked);

      const updatedData = users?.map((u) => ({
        ...u,
        isSelected: u.roles.includes(Role.OWNER) && isChecked,
      }));
      setUsers(updatedData);
    };

    if (features.isAnnualReportPmEnabled) {
      cols.unshift({
        // eslint-disable-next-line react/no-unstable-nested-components
        header: () => (
          <Checkbox
            color="primary"
            sx={selectAllUsers ? {
              '& .MuiSvgIcon-root': {
                fill: theme.palette.primary.dark,
              },
            } : undefined}
            checked={selectAllUsers}
            onChange={selectOwners}
          />
        ),
        accessorKey: 'select',
        size: 100,
        maxSize: 210,
        enableSorting: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info: any) => {
          const handleRowSelectionChange = (event: any) => {
            const isChecked = event.target.checked;

            const u = users?.find(((user) => info.row.original.id === user.id));
            if (u) {
              u.isSelected = isChecked;
            }
            setUsers([...(users ?? [])]);
          };

          return (

            <Stack>
              {info.row.original.roles.includes(Role.OWNER) ? (
                <Checkbox
                  sx={info.row.original.isSelected ? {
                    '& .MuiSvgIcon-root': {
                      fill: theme.palette.primary.dark,
                    },
                  } : undefined}
                  checked={info.row.original.isSelected}
                  onChange={handleRowSelectionChange}
                  disabled={!info.row.original.roles.includes(Role.OWNER)}
                />
              ) : (
                <LightTooltip
                  arrow
                  enterTouchDelay={0}
                  title={(
                    <Stack>
                      <Typography variant="body2" sx={{ p: 1 }}>
                        {l['pm-dashboard.owner.nonSfrSelected']}
                      </Typography>
                    </Stack>
                  )}
                >
                  <span>
                    <Checkbox
                      sx={info.row.original.isSelected ? {
                        '& .MuiSvgIcon-root': {
                          fill: theme.palette.primary.dark,
                        },
                      } : undefined}
                      checked={info.row.original.isSelected}
                      disabled={!info.row.original.roles.includes(Role.OWNER)}
                    />
                  </span>
                </LightTooltip>
              )}
            </Stack>

          );
        },
      });
    }

    cols.push({
      header: l.activated,
      accessorKey: 'login-as',
      size: 100,
      maxSize: 210,
      enableSorting: true,
      sortingFn: sortColumnByActivated,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Typography variant="body2">{info.row.original.isActivated ? l.yes : l.no}</Typography>
      ),
    });

    cols.push({
      header: l.sfrOwner,
      accessorKey: 'isOwner',
      minSize: 80,
      maxSize: 210,
      enableSorting: true,
      sortingFn: sortColumnByIsOwner,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Typography variant="body2">
          {info.row.original.roles.filter((r) => r === 'owner').length > 0 ? l.yes : l.no}
        </Typography>
      ),
      meta: { textNoWrap: true },
    });

    cols.push({
      header: l['pm-dashboard.owner.reportSent'],
      accessorKey: 'reportSent',
      minSize: 80,
      maxSize: 210,
      enableSorting: true,
      sortingFn: (a: Row<APIUser>, b: Row<APIUser>) => {
        const aInvitationDate = getInvitationById(a.original.email);
        const bInvitationDate = getInvitationById(b.original.email);

        if (aInvitationDate > bInvitationDate) {
          return 1;
        }

        if (aInvitationDate < bInvitationDate) {
          return -1;
        }

        return 0;
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Typography variant="body2">
          {getInvitationById(info.row.original.email) !== ''
            ? formatPotentialTodayDate(getInvitationById(info.row.original.email)) : ''}
        </Typography>
      ),
      meta: { textNoWrap: true },
    });

    cols.push({
      header: ' ',
      accessorKey: 'actions',
      enableSorting: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Stack spacing={2} direction="row">
          {(features.isRetainProductEnabled)
            && (info.row.original.roles.includes(Role.OWNER) || info.row.original.roles.includes(Role.MARKETPLACE))
            && (
              <TableAction
                sendOwnerReport={sendOwnerReport}
                isAnnualReportSummaryCreate={pmSettings !== null}
                smsText={getSMSText(info.row.original)}
                user={info.row.original}
              />
            )}
        </Stack>
      ),
    });

    return cols;
  }, [isMobile, inviteNotificationTemplate, features]);

  useEffect(() => {
    if (isError) {
      toast.error(l['error.unknownError']);
    }
  }, [isError]);

  const sendOwnerReport = async (emails: string[]) => {
    await inviteAnnualReportOwners(emails);
    await queryClient.invalidateQueries([QueryKey.ANNUAL_REPORT_INVITATION, loggedInUser?.pm]);
    const updatedData = users?.map((u) => ({
      ...u,
      isSelected: false,
    }));
    setSelectAllUsers(false);
    setUsers(updatedData);
    setConfirmReportSummaryOpen(false);
    toast.success(l.dynamic['owner.successMessage'](emails.length));
  };

  const sendOwnerReportClick = async () => {
    const ownerToSend = owners.filter((o) => o.isSelected && o.roles.includes(Role.OWNER));
    await sendOwnerReport(ownerToSend.map((ow) => ow.email));
  };

  const getSentReportTitle = (): string => {
    if (!pmSettings?.leadershipSummary2024) {
      return l['annualReport.owner.noSummarySet'];
    }

    const ownerToSend = owners.filter((o) => o.isSelected && o.roles.includes(Role.OWNER));

    if (ownerToSend.length === 0) {
      return l['annualReport.owner.noOwnerSelected'];
    }

    return '';
  };

  const getConfirmationMessage = (): string => {
    const ownersSelected = owners.filter((o) => o.isSelected && o.roles.includes(Role.OWNER)).length;
    const notValidUsers = owners.filter((o) => o.isSelected).length - ownersSelected;

    if (notValidUsers > 0) {
      return `The annual report is going to be sent to ${ownersSelected} ${ownersSelected === 1 ? 'owner' : 'owners'}.
       However, ${notValidUsers} ${notValidUsers === 1 ? 'owner' : 'owners'}
       will not receive the report as they are not classified as Single Family Rental (SFR) owners.`;
    }

    return `The annual report will be sent to ${ownersSelected} ${ownersSelected === 1 ? 'owner' : 'owners'}.
    This step helps strengthen owner relationships with key property insights. Proceed?`;
  };

  const totalClickedReport = annualInvitationsData?.filter((invitation) => invitation.emailClicked).length ?? 0;

  return (
    <>
      <YesNoDialog
        open={confirmReportSummaryOpen}
        isLoading={isLoadingInviteAnnualReportOwners}
        onClose={() => setConfirmReportSummaryOpen(false)}
        title={l['pm-dashboard.report.confirmationTitle']}
        description={getConfirmationMessage()}
        onSubmit={sendOwnerReportClick}
      />
      <AdminView
        tableHeader={(
          <Stack direction="row" alignItems="center" gap={3}>
            {features.isAnnualReportPmEnabled && (
              <LightTooltip
                arrow
                enterTouchDelay={0}
                title={getSentReportTitle() === '' ? undefined : (
                  <Stack>
                    <Typography variant="body2" sx={{ p: 1 }}>
                      {getSentReportTitle()}
                    </Typography>
                  </Stack>
                )}
              >
                <Box component="span">
                  <InvertedButton
                    type="button"
                    size="medium"
                    onClick={() => setConfirmReportSummaryOpen(true)}
                    disabled={owners.filter((o) => o.isSelected && o.roles.includes(Role.OWNER)).length === 0
                      || !pmSettings?.leadershipSummary2024}
                    startIcon={<GoGraph />}
                  >
                    {l['pm-dashboard.owners.sendReport']}
                  </InvertedButton>
                </Box>
              </LightTooltip>
            )}
          </Stack>
        )}
        title={renderedOnRoot ? l['menu-owners'] : undefined}
        searchPlaceholder={l['pm-dashboard.owner.search']}
        data={isLoading ? [] : owners}
        columns={columns}
        searchTermFilter={(currProperties, search) => currProperties.filter(
          (p) => (p.email + p.name).toLowerCase().includes(search.toLowerCase()),
        )}
        initSearch={searchParams.get('search') ?? ''}
        pageHeader={(
          <Grid container spacing={2}>
            <Grid item xs={12} lg={features.isAnnualReportPmEnabled ? 6 : 12}>
              <DetailedMetric
                title={l.total}
                metrics={[
                  {
                    id: 'invited-owners',
                    value: owners?.length,
                    label: l.invited,
                    icon: <ActivatedOwner />,
                  },
                  {
                    id: 'signed-up-owners',
                    value: activatedOwners.length,
                    label: l.activated,
                    icon: <ActivatedOwner />,
                  },
                  {
                    id: 'owners-conversion-rate',
                    value: formatDecimalToPercentage(owners?.length ? activatedOwners.length / owners.length : 0, 2),
                    label: l['pm-dashboard.owner.conversion-rate'],
                    icon: <PercentGreenBig />,
                  },
                ]}
                onClick={() => { }}
              />
            </Grid>
            {features.isAnnualReportPmEnabled && (
              <Grid item xs={12} lg={6}>
                <ReportAnnualInvitationMetric
                  users={data ?? []}
                  title={l['pm-dashboard.owner.annualReport2024']}
                  metrics={[
                    {
                      id: 'total-sent-invitations',
                      value: annualInvitationsData?.length,
                      label: l['annualReport.TotalSent'],
                      icon: <PersonArrowDown />,
                    },
                    {
                      id: 'total-sent-invitations-received',
                      value: annualInvitationsData?.filter((invitation) => invitation.emailClicked).length,
                      label: l['annualReport.owner.ClickedReport'],
                      icon: <PersonArrowDown />,
                    },
                    {
                      id: 'click-through-rate',
                      value: totalClickedReport !== 0 ? (formatDecimalToPercentage((totalClickedReport)
                        / (annualInvitationsData?.length ?? 0), 2)) : 0,
                      label: l['click-through-rate'],
                      icon: <PercentGreenBox style={{ color: theme.palette.primary.main }} />,
                    },
                  ]}
                />
              </Grid>
            )}

          </Grid>
        )}
      />
    </>

  );
};
