import { memo, ReactElement } from 'react';

import {
  useListMarketAnalysisFiltered,
} from 'lib';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { MarketAnalysisGraph } from './MarketAnalysisGraph';
import { DollarCircleBlack } from '../../icons';
import { useLabels } from '../../lib/translations';
import { SpinnerWithLogo } from '../../spinner';
import { BoldTypography } from '../../typography/BoldTypography';
import { BlurredComponent } from '../BlurredComponent';

export const MarketAnalysis = memo(({
  fips, selectComponent,
}: { fips: string, selectComponent: ReactElement }) => {
  const { data: marketData, isLoading } = useListMarketAnalysisFiltered(fips);
  const l = useLabels();

  const rentAllSeries: number[] = [];
  const rentSFRSeries: number[] = [];
  const valueAllSeries: number[] = [];
  const valueSFRSeries: number[] = [];

  if (marketData) {
    const sortedMarketData = marketData.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );

    sortedMarketData.forEach((marketAnalsysData) => {
      rentAllSeries.push(marketAnalsysData.rentAll ?? 0);
      rentSFRSeries.push(marketAnalsysData.rentSFR ?? 0);
      valueSFRSeries.push(marketAnalsysData.homeValueSFR ?? 0);
      valueAllSeries.push(marketAnalsysData.homeValueAll ?? 0);
    });
  }

  if (isLoading) {
    return <SpinnerWithLogo />;
  }

  if (marketData.length === 0) {
    return null;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Stack direction="row" alignItems="center">
          <DollarCircleBlack />
          <BoldTypography variant="body1">
            {l['annualReport.marketAnalysis.title']}
            {' '}
            (
            {marketData && marketData[0].areaName}
            )
          </BoldTypography>
        </Stack>
        {selectComponent}
      </Stack>
      <Typography
        variant="body1"
        sx={{
          '&& .good': {
            color: '#0c7b60',
          },
          '&& .bad': {
            color: '#b7474f',
          },
        }}
        dangerouslySetInnerHTML={{ __html: marketData[0].marketSummary ?? '' }}
        className="dynamic-html"
      />
      <MarketAnalysisGraphs
        rentSFRSeries={rentSFRSeries}
        rentAllSeries={rentAllSeries}
        valueSFRSeries={valueSFRSeries}
        valueAllSeries={valueAllSeries}
      />
    </>
  );
});

export const calculateMinLength = (series: number[]) => {
  const noZeroes = series.filter((value) => value !== 0);
  return Math.min(...noZeroes);
};

export const MarketAnalysisGraphs = ({
  rentSFRSeries,
  valueSFRSeries,
  rentAllSeries,
  valueAllSeries,
}: {
  rentSFRSeries: number[],
  valueSFRSeries: number[],
  rentAllSeries: number[],
  valueAllSeries: number[]
}) => {
  const l = useLabels();

  const rentSFRParsed = rentSFRSeries.filter((value) => value !== 0);
  const rentAllParsed = rentAllSeries.filter((value) => value !== 0);
  const valueSFRParsed = valueSFRSeries.filter((value) => value !== 0);
  const valueAllParsed = valueAllSeries.filter((value) => value !== 0);
  const rentAllEmpty = rentAllParsed.length === 0;
  const rentSFREmpty = rentSFRParsed.length === 0;
  const valueSFREmpty = valueSFRParsed.length === 0;
  const valueAllEmpty = valueAllParsed.length === 0;

  const minLength = calculateMinLength([
    rentSFRParsed.length,
    rentAllParsed.length,
    valueSFRParsed.length,
    valueAllParsed.length,
  ]);

  const rentSFRSliced = rentSFRParsed.slice(-minLength);
  const rentAllSliced = rentAllParsed.slice(-minLength);
  const valueSFRSliced = valueSFRParsed.slice(-minLength);
  const valueAllSliced = valueAllParsed.slice(-minLength);

  return (
    <Stack gap={6}>
      <Stack direction="row" justifyContent="space-between" gap={6}>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={6} spacing={3}>
            <Grid item xs={12}>
              <BoldTypography variant="subtitle1">
                {l['annualReport.marketAnalysis.singleFamily']}
              </BoldTypography>
            </Grid>
            <Grid item xs={12}>
              <BlurredComponent title="" subtitle={l['annualReport.noDataAvailable']} isBlurred={rentSFREmpty}>
                <MarketAnalysisGraph
                  title={l['annualReport.marketAnalysis.rent']}
                  series={rentSFRSliced}
                  color="purple"
                  scale={1_000}
                />
              </BlurredComponent>

            </Grid>
            <Grid item xs={12}>
              <BlurredComponent title="" subtitle={l['annualReport.noDataAvailable']} isBlurred={valueSFREmpty}>
                <MarketAnalysisGraph
                  title={l['annualReport.marketAnalysis.homeValue']}
                  series={valueSFRSliced}
                  color="blue"
                  getHalfMin
                />
              </BlurredComponent>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} spacing={3}>
            <Grid item xs={12}>
              <BoldTypography variant="subtitle1">
                {l['annualReport.marketAnalysis.allProperties']}
              </BoldTypography>
            </Grid>
            <Grid item xs={12}>
              <BlurredComponent title="" subtitle={l['annualReport.noDataAvailable']} isBlurred={rentAllEmpty}>
                <MarketAnalysisGraph
                  title={l['annualReport.marketAnalysis.rent']}
                  series={rentAllSliced}
                  color="purple"
                  scale={1_000}
                />
              </BlurredComponent>
            </Grid>
            <Grid item xs={12}>
              <BlurredComponent title="" subtitle={l['annualReport.noDataAvailable']} isBlurred={valueAllEmpty}>
                <MarketAnalysisGraph
                  title={l['annualReport.marketAnalysis.homeValue']}
                  series={valueAllSliced}
                  color="blue"
                  getHalfMin
                />
              </BlurredComponent>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
